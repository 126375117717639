
import { addToCartMethod, isPercentageAssign } from '@Components/Common/CommonMethods';
import NumberFormatComp from '@Components/Common/NumberFormatComp';
import _ from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';
import { _getCartProducts } from '@Components/Common/CommonMethods';

class RelatedProduct extends React.Component {
    constructor(porps) {
        super(porps);
        this.state = {
            selected: null,
            quantity: [{ id: null, qty: 1, maxQty: null }],
            taxPrice: null,
            shippingData: [],
            selectedShipping: { id: null, amount: null },
            frequancy_date: [{ id: null, days: "30" }],
            autoshipData: [{ id: null, autoship: "autoship" }],
            subtotal: null,
            total: null,
            percentage: null,
            condition: true,
        }
        this.setCartNotify = this._setCartNotify.bind(this)
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                quantity: _.map(this.props?.relatedProduct, (row) => ({
                    id: row?.id, qty: this.props?.selectedProductFreq?.product_id === +row?.id ? +this.props?.selectedProductFreq?.quantity : 1, maxQty: row?.quantity
                }))
            });
            this.setState({ frequancy_date: _.map(this.props?.relatedProduct, (row) => ({ id: row?.id, days: "30" })) });
            this.setState({ autoshipData: _.map(this.props?.relatedProduct, (row) => ({ id: row?.id, autoship: "autoship" })) });
            this.setState({ percentage: isPercentageAssign() })
            if (this.props?.selectedProductFreq?.product_id) {
                this.setState({
                    selected: this.props?.selectedProductFreq?.product_id,
                    subtotal: this.props?.selectedProductFreq?.quantity * this.props?.selectedProductFreq?.amount,
                    total: this.props?.selectedProductFreq?.quantity * this.props?.selectedProductFreq?.amount
                })
            }
        }, 4000)
    }
    _setCartNotify(msg) {
        this.setState({ addToCartMsg: msg });
    }

    _addToCartApplied(id) {
        const getUserAutoShip = JSON.parse(localStorage.getItem("profileData"))?.user_data?.userdetails?.[0]?.is_autoship_user;
        this.props.setSelectedProductFreq({
            ...this.props.selectedProductFreq,
            quantity: _.find(this.state.quantity, { id })?.qty,
            shipping_frequency: +(_.find(this.state.frequancy_date, { id })?.days),
            is_autoship: _.find(this.state.autoshipData, { id })?.autoship
        });

        addToCartMethod(
            {
                id: id,
                quantity: _.find(this.state.quantity, { id })?.qty,
                is_autoship: getUserAutoShip !== "False" || _.find(this.state.autoshipData, { id })?.autoship === "one-time" ? false : true,
            },
            this.props?.setshowminicart,
            this.setCartNotify,
            this.props?.setcoupon(''),
            this.props?.setAmountCalculations(),
            this.props?.setdiscount_amount(0)
        )

    }
    componentDidUpdate(prevProps, prvState) {
        const data = localStorage.getItem("profileData") ? !JSON.parse(localStorage.getItem("profileData"))?.user_data?.userdetails?.[0]?.test_report_release : true;
        if (prvState?.condition !== data) {
            this.setState({
                condition: data
            })
        }
    }
    _onQtyfuntion(section, id) {
        // let OPTION = this.state.quantity;
        const QTY = _.find(this.state.quantity, { id: id });
        const MAX_QTY = QTY?.maxQty;
        const CURRENT_QTY = QTY?.qty;

        if (section === "add") {
            if (CURRENT_QTY < MAX_QTY) {
                QTY['qty'] = QTY?.qty + 1;
                const REJECTED_DATA = _.reject(this.state.quantity, QTY);
                this.setState({ quantity: [...REJECTED_DATA, QTY] });
            }
        } else {
            if (CURRENT_QTY !== 1) {
                QTY['qty'] = QTY?.qty - 1;
                const REJECTED_DATA = _.reject(this.state.quantity, { id: id });
                this.setState({ quantity: [...REJECTED_DATA, QTY] });
            }
        }
    }
    _setAutoshipFunction(event, id) {
        const FIND_VALUE = _.find(this.state.autoshipData, { id: id });
        if (FIND_VALUE) {
            FIND_VALUE['autoship'] = event.target.value;
            const REMAIN_DATA = _.reject(this.state.autoshipData, FIND_VALUE);
            this.setState({ autoshipData: [...REMAIN_DATA, FIND_VALUE] });
        }
    }

    _setShippingFrequency(event, id) {
        const FIND_VALUE = _.find(this.state.frequancy_date, { id: id });
        if (FIND_VALUE) {
            FIND_VALUE['days'] = event.target.value;
            const REMAIN_DATA = _.reject(this.state.frequancy_date, FIND_VALUE);
            this.setState({ frequancy_date: [...REMAIN_DATA, FIND_VALUE] });
        }
    }

    render() {
        const { selectedProductFreq, relatedProduct, _onSelectChange, selectedProduct, incQty, handleChangeQty, setDecQty, addProductToCart, cartdata, product, autoshipCheck, } = this.props;
        const CUSTOM_PRODUCTS = cartdata?.products?.filter((item) => item?.product?.is_custom_product === "True");
        const Avaible_products = cartdata?.products?.filter((item) => item?.product?.sku === "KIT-SURVEY");
        const ancestry_product_data = cartdata?.products?.filter((item) => item?.product?.sku === "KIT-SURVEY-ANCESTRY");
        let REJECT_CUSTOM_PRODUCT = _.filter(relatedProduct, function (row) { return ["SN-CC-PWDR-30", "SN-CC-PWDR-30-NEW", "SN-CC-CAPS-30", "SN-CC-CAPS-30-NEW"]?.includes(row?.sku) });

        const PRODUCTS_SKU = ["SN-CC-CAPS-30-NEW", "SN-CC-CAPS-30", "SN-CC-PWDR-30-NEW", "SN-CC-PWDR-30",
            "SN-CC-CAPS-30-NEW_Subscription", "SN-CC-CAPS-30_Subscription", "SN-CC-PWDR-30-NEW_Subscription", "SN-CC-PWDR-30_Subscription"]

        const FILTER_PACKS = REJECT_CUSTOM_PRODUCT?.map((row) => row?.sku) || [];
        const matchingItemIDS = PRODUCTS_SKU?.filter(sku => FILTER_PACKS?.includes(sku));
        const optionValueCaps = matchingItemIDS?.[0] === "SN-CC-CAPS-30" ? "SN-CC-CAPS-30" : "SN-CC-CAPS-30-NEW"
        const optionValuePwdr = matchingItemIDS?.[1] === "SN-CC-PWDR-30" ? "SN-CC-PWDR-30" : "SN-CC-PWDR-30-NEW"
        const subscriptinValueCaps = matchingItemIDS?.[0] === "SN-CC-CAPS-30" ? "SN-CC-CAPS-30_Subscription" : "SN-CC-CAPS-30-NEW_Subscription"
        const subscriptinValuePwdr = matchingItemIDS?.[1] === "SN-CC-PWDR-30" ? "SN-CC-PWDR-30_Subscription" : "SN-CC-PWDR-30-NEW_Subscription"


        return (
            <div className="shipping">
                <>
                    <div className='heading_section'>
                        <h3>Sign up for your customized CODE Complex™ supplement subscription and <span>SAVE up to $30 off</span></h3>
                    </div>
                    <div className='cart-row'>
                        <div style={{ borderRadius: "30px" }}>
                            {_.map(REJECT_CUSTOM_PRODUCT, (row, index) => {
                                let AUTOSHIP_PRICE = this.state.percentage ? row?.autoship_cost_price - row?.autoship_cost_price * (this.state.percentage / 100) : row?.autoship_cost_price;
                                let COST_PRICE = this.state.percentage ? row?.cost_price - row?.cost_price * (this.state.percentage / 100) : row?.cost_price;
                                let REAL_PRICE = this.state.percentage ? row?.real_amount - row?.real_amount * (this.state.percentage / 100) : row?.real_amount;
                                const AUTOSHIP_VALUE = ["SN-CC-CAPS-30_Subscription", "SN-CC-PWDR-30_Subscription", "SN-CC-CAPS-30-NEW_Subscription", "SN-CC-PWDR-30-NEW_Subscription"]?.includes(selectedProduct) ? "autoship" : "one-time";
                                return (
                                    <>
                                        {index === 0 &&
                                            <>
                                                <div key={row?.id} className='viewcart-table-data'>
                                                    <div className='new-check-compo d-flex'>
                                                        <div className='radio_section_div' style={{ display: 'flex', flexDirection: 'column', justifyContent: "center" }}>
                                                            <div className='image'>
                                                                {product?.product_images ? <img src={process.env.API_URL + product?.product_images?.[0]?.image} width="80" height="60" /> :
                                                                    <img src="/images/CODE_Complex_capsules.png" width="80" height="60" />
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className='text_realted_css'>
                                                            {product?.name}

                                                        </div>

                                                        <div className={(AUTOSHIP_VALUE === "one-time") ? 'amount_value' : 'amount_strick'}>
                                                            {autoshipCheck ? <NumberFormatComp value={REAL_PRICE} /> : <NumberFormatComp value={COST_PRICE} />}

                                                            {!this.props?.subscribeAlready && autoshipCheck &&
                                                                <NumberFormatComp className="final-price" value={AUTOSHIP_PRICE} />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='qty-subscription-data d-flex'>
                                                        <select className="form-select-product" value={selectedProduct} onChange={(e) => _onSelectChange(e.target.value)}>
                                                            {!this.props?.subscribeAlready &&
                                                                <>
                                                                    <option value={subscriptinValueCaps}>{`CODE Complex \u2122 DNA Personalized Nutrition (Subscription) - Capsules (30-day Supply) ${AUTOSHIP_PRICE}`}</option>
                                                                    <option value={subscriptinValuePwdr}>{`CODE Complex \u2122 DNA Personalized Nutrition (Subscription) - Raw Unflavored Powder (30-day supply) ${AUTOSHIP_PRICE}`}</option>

                                                                </>
                                                            }
                                                            <option value={optionValueCaps}>{`CODE Complex \u2122 DNA Personalized Nutrition (One-time order) - Capsules (30-day Supply) ${COST_PRICE}`}</option>
                                                            <option value={optionValuePwdr}>{`CODE Complex \u2122 DNA Personalized Nutrition (One-time order) - Raw Unflavored Powder (30-day supply) ${COST_PRICE}`}</option>

                                                        </select>

                                                        <div className="name_tableData" >
                                                            <div className="main-qty-secc">
                                                                <div id="qty">
                                                                    <button type="button" className="sub" onClick={(e) => handleChangeQty("sub")}>-</button>
                                                                    <input name="1" readOnly type="text" value={incQty} onChange={() => console.log('error')} />
                                                                    <button type="button" className="add" onClick={(e) => handleChangeQty("add")}>+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='add_to_cart' >
                                                        <button type="button" className='related-products-btn' title={CUSTOM_PRODUCTS?.length === 1 ? "You can add only one custom product" : ""}
                                                            disabled={CUSTOM_PRODUCTS?.length === 1}
                                                            onClick={() => addProductToCart()}>Add to Cart</button>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </>

            </div>
        )
    }

};

export default RelatedProduct;